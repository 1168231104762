<template>
  <div class="pro_box">
    <div class="title flex_center_between_box">
      <div class="title_txt flex_center_start_box"><img src="../../assets/img/detail/title_ic11.png" />Country Economy</div>
      <div class="btn_export flex_center" @click="viewHistory"><img src="../../assets/img/detail/btn_ic4.png" />View History</div>
    </div>
    <div class="right_cont_box">
      <div class="group profile">
        <div class="item">
          <label class="lab">GDP (PPP):</label>
          <div class="txt">{{ objEconomy.gdpPpp ? objEconomy.gdpPpp : '-' }}</div>
          <div class="num">
            {{ objEconomy.gdpRank }}
            <img v-if="objEconomy.gdpRank" src="../../assets/img/detail/up_img.png" />
            <img v-else src="../../assets/img/detail/down_img.png" />
          </div>
        </div>
        <div class="item">
          <label class="lab">GDP / Capita:</label>
          <div class="txt">{{ objEconomy.gdpper ? objEconomy.gdpper : '-' }}</div>
          <div class="num">
            {{ objEconomy.gdpPppGrowthRate }}
            <img v-if="!objEconomy.gdpPppGrowthRate.includes('-')" src="../../assets/img/detail/up_img.png" />
            <img v-else src="../../assets/img/detail/down_img.png" />
          </div>
        </div>
        <div class="item">
          <label class="lab">Foreign Reserves:</label>
          <div class="txt">{{ objEconomy.foreignReserves ? objEconomy.foreignReserves : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">FDI Inflows: </label>
          <div class="txt">{{ objEconomy.fdiInflows ? objEconomy.fdiInflows : '-' }}</div>
          <div class="num">
            {{ objEconomy.fdiInflowsGrowthRate }}
            <img v-if="!objEconomy.fdiInflowsGrowthRate.includes('-')" src="../../assets/img/detail/up_img.png" />
            <img v-else src="../../assets/img/detail/down_img.png" />
          </div>
        </div>
        <div class="item">
          <label class="lab">FDI Outflows:</label>
          <div class="txt">{{ objEconomy.fdiOutflows ? objEconomy.fdiOutflows : '-' }}</div>
          <div class="num">
            {{ objEconomy.fdiOutflowsGrowthRate }}
            <img v-if="!objEconomy.fdiOutflowsGrowthRate.includes('-')" src="../../assets/img/detail/up_img.png" />
            <img v-else src="../../assets/img/detail/down_img.png" />
          </div>
        </div>
        <div class="item">
          <label class="lab">Government Debt:</label>
          <div class="txt">{{ objEconomy.governmentDebt ? objEconomy.governmentDebt : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Government Debt to GDP:</label>
          <div class="txt">{{ objEconomy.governmentDebtToGdp ? objEconomy.governmentDebtToGdp : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Central Bank Balance Sheet:</label>
          <div class="txt">{{ objEconomy.centralBankBalance ? objEconomy.centralBankBalance : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Money Supply M1:</label>
          <div class="txt">{{ objEconomy.moneySupplyM1GrowthRate ? objEconomy.moneySupplyM1 : '-' }}</div>
          <div class="num">
            {{ objEconomy.moneySupplyM1GrowthRate }}
            <img v-if="!objEconomy.moneySupplyM1GrowthRate.includes('-')" src="../../assets/img/detail/up_img.png" />
            <img v-else src="../../assets/img/detail/down_img.png" />
          </div>
        </div>
        <div class="item">
          <label class="lab">Money Supply M2:</label>
          <div class="txt">{{ objEconomy.moneySupplyM2 ? objEconomy.moneySupplyM2 : '-' }}</div>
          <div class="num">
            {{ objEconomy.moneySupplyM2GrowthRate }}
            <img v-if="!objEconomy.moneySupplyM2GrowthRate.includes('-')" src="../../assets/img/detail/up_img.png" />
            <img v-else src="../../assets/img/detail/down_img.png" />
          </div>
        </div>
        <div class="item">
          <label class="lab">Interest Rate:</label>
          <div class="txt">{{ objEconomy.interestRate ? objEconomy.interestRate : '-' }}</div>
          <div class="num">
            {{ objEconomy.InterestRateNum }}
            <img v-if="!objEconomy.interestRate.includes('-')" src="../../assets/img/detail/up_img.png" />
            <img v-else src="../../assets/img/detail/down_img.png" />
          </div>
        </div>
        <div class="item">
          <label class="lab">Inflation Rate:</label>
          <div class="txt">{{ objEconomy.inflationRate ? objEconomy.inflationRate : '-' }}</div>
          <div class="num">
            <img v-if="!objEconomy.inflationRate.includes('-')" src="../../assets/img/detail/up_img.png" />
            <img v-else src="../../assets/img/detail/down_img.png" />
          </div>
        </div>
        <div class="item">
          <label class="lab">Consumer Price Index (CPI): </label>
          <div class="txt">{{ objEconomy.consumerPriceIndex ? objEconomy.consumerPriceIndex : '-' }}</div>
          <div class="num">
            {{ objEconomy.consumerPriceIndexGrowthRate }}
            <img v-if="!objEconomy.consumerPriceIndexGrowthRate.includes('-')" src="../../assets/img/detail/up_img.png" />
            <img v-else src="../../assets/img/detail/down_img.png" />
          </div>
        </div>
        <div class="item">
          <label class="lab">Consumer Confidence:</label>
          <div class="txt">{{ objEconomy.consumerConfidence ? objEconomy.consumerConfidence : '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { searchCountryInfo } from '@/api/companyApi.js'
import crypto from '@/utils/crypto'

export default {
  data() {
    return {
      objEconomy: {
        gdpPpp: '',
        gdpRank: '',
        gdpper: '',
        foreignReserves: '',
        fdiInflows: '',
        fdiOutflows: '',
        governmentDebt: '',
        governmentDebtToGdp: '',
        centralBankBalance: '',
        moneySupplyM1: '',
        moneySupplyM1Unit: '',
        moneySupplyM2: '',
        interestRate: '',
        inflationRate: '',
        consumerPriceIndex: '',
        consumerConfidence: '',
        gdpPppGrowthRate: '',
        economicGrowthRate: '',
        fdiInflowsGrowthRate: '',
        fdiOutflowsGrowthRate: '',
        moneySupplyM1GrowthRate: '',
        moneySupplyM2GrowthRate: '',
        interestRateGrowthRate: '',
        inflationRateGrowthRate: '',
        consumerPriceIndexGrowthRate: '',
      },
      companyCountry:'',
    }
  },
  computed: {
    //index传值 是否翻译
    showTranslate: {
      get() {
        return this.$parent.indexIsTranslate
      },
      set() {},
    },
  },
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.companyCountry = this.$route.query.companyCountry

      const params = {
        types: ['CE'],
        countryCodes: [this.companyCountry],
        // years: [''],
      }
      searchCountryInfo(params).then((result) => {
        let data = JSON.parse(crypto.decrypt(result))
        if (data && data.code && data.data) {
          console.log('result- economy', data.data)
          let obj = data.data.countryEconomyList ? data.data.countryEconomyList[0] : ''
          this.objEconomy = {
            gdpPpp: obj.gdpPpp,
            gdpRank: obj.gdpRank,
            gdpper: obj.gdpper,
            gdpPppGrowthRate: obj.gdpPppGrowthRate,
            foreignReserves: obj.foreignReserves,
            fdiInflows: obj.fdiInflows,
            fdiOutflows: obj.fdiOutflows,
            governmentDebt: obj.governmentDebt,
            governmentDebtToGdp: obj.governmentDebtToGdp,
            centralBankBalance: obj.centralBankBalance,
            moneySupplyM1: obj.moneySupplyM1,
            moneySupplyM1Unit: obj.moneySupplyM1Unit,
            moneySupplyM2: obj.moneySupplyM2,
            moneySupplyM2Unit: obj.moneySupplyM2Unit,
            interestRate: obj.interestRate,
            inflationRate: obj.inflationRate,
            consumerPriceIndex: obj.consumerPriceIndex,
            consumerConfidence: obj.consumerConfidence,
            fdiInflowsGrowthRate: obj.fdiInflowsGrowthRate,
            fdiOutflowsGrowthRate: obj.fdiOutflowsGrowthRate,
            moneySupplyM1GrowthRate: obj.moneySupplyM1GrowthRate,
            moneySupplyM2GrowthRate: obj.moneySupplyM2GrowthRate,
            interestRateGrowthRate: obj.interestRateGrowthRate,
            inflationRateGrowthRate: obj.inflationRateGrowthRate,
            consumerPriceIndexGrowthRate: obj.consumerPriceIndexGrowthRate,
          }
        }
      })
    },

    //跳转
    viewHistory() {
      this.$router.push({ path: '/country/viewhistory', query: { companyCountry: this.companyCountry } })
    },
  },
}
</script>
<style scoped>
.title {
  margin-bottom: 20px;
}
.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}
.title_txt img {
  width: 20px;
  margin-right: 8px;
}
.pro_box {
  margin-top: 22px;
}
.group.profile {
  padding: 0 10px;
}
.profile .item {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7eaed;
}
.profile .item:first-child {
  padding-top: 10px;
}
.profile .item:last-child {
  padding-bottom: 10px;
  border-width: 0;
}
.profile .item .lab {
  width: 208px;
  font-size: 16px;
  color: #8497ab;
}
.profile .item .txt {
  width: 190px;
  margin-left: 40px;
  color: #022955;
  font-size: 16px;
}
.profile .item .num {
  margin-left: 50px;
  line-height: 16px;
  font-size: 14px;
  color: #333333;
}
.profile .item .num img {
  width: 10px;
  height: 12px;
}
</style>
